<template>
  <section class="home" id="home">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="home-wrapper text-center">
            <h1 class="animated fadeInDown wow" data-wow-delay=".1s">
              {{$t("Development")}}
              <span class="text-colored">{{$t("Portfolio")}}</span>
            </h1>
            <p
              class="animated fadeInDown wow text-muted"
              data-wow-delay=".2s"
            >{{$t("We are a company focused on the development of cloud technologies, we offer computing services in providers such as Google Compute Platform, AWS and Azure, which range from the administration of third-party platforms to the development of self-scalable systems.")}}</p>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "DevelopmentHome"
};
</script>
<style scoped>
.home-wrapper {
  padding: 150px 0px 100px 0px !important;
}
</style>
