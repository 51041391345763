<template>
  <div>
    <DevelopmentHome />
    <InitialSetup />
    <BackEnd />
    <FrontEnd />
    <Commitment />
  </div>
</template>

<script>
import DevelopmentHome from "./DevelopmentHome.vue";
import InitialSetup from "./InitialSetup.vue";
import BackEnd from "./BackEnd.vue";
import FrontEnd from "./FrontEnd.vue";
import Commitment from "./Commitment.vue";
export default {
  name: "DevelopmentIndex",
  components: {
    DevelopmentHome,
    InitialSetup,
    BackEnd,
    FrontEnd,
    Commitment
  }
};
</script>


